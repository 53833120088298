import React, { useState, useEffect } from 'react';
import { DataBrowser } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import teacherQuery from './teachers.graphql';

const Fortress = window.cccisd.fortress;

const GoalData = props => {
    const [loading, setLoading] = useState(true);
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        getTeachers();
        setLoading(false);
    }, []);

    const getTeachers = async () => {
        const response = await client.query({
            query: teacherQuery,
            variables: {},
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        setTeachers(response.data.roles.teacherList.map(t => t.pawn.pawnId));
    };

    if (loading) {
        return <Loader loading />;
    }

    return (
        <div className="container-fluid">
            <DataBrowser
                key={Fortress.user.acting.id}
                applyPawnContext
                projectId={3}
                constantFilter={{ in: { field: 'pawnId', int: teachers } }}
            />
        </div>
    );
};

export default GoalData;
